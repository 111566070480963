<template>
  <v-dialog :value="showCreateFolderDialog" width="400px">
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div>Create Folder</div>
        <v-spacer />
        <v-btn icon @click="$emit('create-folder-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <form class="px-5" @submit.prevent="createFolder">
        <validation-observer ref="form">
          <validation-provider
            v-slot="{ errors, valid }"
            name="folderName"
            rules="required"
          >
            <v-text-field
              v-model="folderName"
              :error-messages="errors"
              :success="valid"
              label="Folder Name"
              color="#3F51B5"
            >
            </v-text-field>
          </validation-provider>

          <div class="d-flex justify-end py-3">
            <v-btn type="submit" color="#3F51B5" dark> Create Folder </v-btn>
          </div>
        </validation-observer>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";

export default {
  name: "CreateFolderDialog",
  data() {
    return { mdiClose, folderName: "" };
  },
  props: {
    showCreateFolderDialog: Boolean,
  },
  methods: {
    async createFolder() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      this.$emit("create-folder", this.folderName);
    },
  },
};
</script>
