var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showConfirmFileDeleteDialog, width: "500px" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "py-4" }, [
            _c("p", { staticClass: "py-2" }, [
              _vm._v("Are you sure you want to delete this item?")
            ])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "#3F51B5", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "#3F51B5", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("delete")
                    }
                  }
                },
                [_vm._v(" Delete ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }