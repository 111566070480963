var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showCreateFolderDialog, width: "400px" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("div", [_vm._v("Create Folder")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("create-folder-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "form",
            {
              staticClass: "px-5",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.createFolder.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "validation-observer",
                { ref: "form" },
                [
                  _c("validation-provider", {
                    attrs: { name: "folderName", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          var valid = ref.valid
                          return [
                            _c("v-text-field", {
                              attrs: {
                                "error-messages": errors,
                                success: valid,
                                label: "Folder Name",
                                color: "#3F51B5"
                              },
                              model: {
                                value: _vm.folderName,
                                callback: function($$v) {
                                  _vm.folderName = $$v
                                },
                                expression: "folderName"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end py-3" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { type: "submit", color: "#3F51B5", dark: "" }
                        },
                        [_vm._v(" Create Folder ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }