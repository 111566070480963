var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showUnsavedChangesWarningDialog,
        "max-width": "500px",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "py-5" }, [
            _vm._v(
              " There are unsaved changes on the form. Are you sure you want close and lose your changes? "
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end gap flex-wrap" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("return-to-form")
                    }
                  }
                },
                [_vm._v(" Return to Form ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close-and-lose-changes")
                    }
                  }
                },
                [_vm._v(" Close and Lose Changes ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }