export const SMART_FIELD = {
  DUE: "task-due",
  USER_SITE_LINK: "user-site-link",
  SITE_CONTACT_SITE_LINK: "site-contact-site-link",
  SITE_NUMBER: "site-number",
  STATUS_WRITTEN: "status-written",
};

export const SMART_FIELD_CHOICES = [
  { label: "Task Due Date", value: SMART_FIELD.DUE },
  { label: "Site Link", value: SMART_FIELD.USER_SITE_LINK },
  {
    label: "Site Link",
    value: SMART_FIELD.SITE_CONTACT_SITE_LINK,
  },
  { label: "Site Number", value: SMART_FIELD.SITE_NUMBER },
  { label: "Status Written", value: SMART_FIELD.STATUS_WRITTEN },
];
