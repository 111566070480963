<template>
  <v-dialog :value="showConvertingMarkupDialog" width="500px">
    <v-card>
      <v-card-text class="py-4">
        <p class="py-2">
          The process of converting this file to a markup format has not been
          completed yet. Please try again in a few minutes.
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="#3F51B5"
          dark
          @click="$emit('cancel-converting-markup-dialog-close')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConvertingMarkupDialog",
  props: {
    showConvertingMarkupDialog: Boolean,
  },
};
</script>
