<template>
  <node-view-wrapper
    as="span"
    class="template-placeholder"
    :data-placeholder="node.attrs.placeholder"
  >
    <span>{{ displayPlaceholder }}</span>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-2";

export default {
  components: {
    NodeViewWrapper,
  },
  props: nodeViewProps,
  computed: {
    displayPlaceholder() {
      return decodeURI(this.node.attrs.placeholder);
    },
  },
};
</script>

<style>
.template-placeholder {
  background: #e0e0e0;
  border-radius: 16px;
  font-size: 14px;
  height: 32px;
  padding: 5px 12px;
}
</style>
