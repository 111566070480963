<template>
  <v-dialog :value="showViewOnlyUserDialog" width="500" persistent>
    <v-card>
      <v-card-text class="pa-5">
        Your user is currently view-only and does not have permission to create
        forms.
      </v-card-text>

      <v-card-actions class="px-5 py-3 d-flex justify-end">
        <v-btn text @click="$emit('view-only-dialog-close')" color="#3F51B5">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ViewOnlyUserDialog",
  props: {
    showViewOnlyUserDialog: Boolean,
  },
};
</script>
