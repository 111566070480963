var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showFileDescriptionDialog, width: "400px" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("div", [_vm._v("Attach File")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("file-description-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "form",
            {
              staticClass: "px-5",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.saveDescription.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "validation-observer",
                { ref: "form" },
                _vm._l(_vm.fileDescriptions, function(f) {
                  return _c(
                    "div",
                    { key: f.fileName, staticClass: "d-flex gap align-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "truncate",
                          staticStyle: { width: "30%" }
                        },
                        [_vm._v(" " + _vm._s(f.fileName) + " ")]
                      ),
                      _c("validation-provider", {
                        attrs: { name: "description" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                var valid = ref.valid
                                return [
                                  _c("v-text-field", {
                                    attrs: {
                                      "error-messages": errors,
                                      success: valid,
                                      label: "Description",
                                      color: "#3F51B5"
                                    },
                                    model: {
                                      value: f.description,
                                      callback: function($$v) {
                                        _vm.$set(f, "description", $$v)
                                      },
                                      expression: "f.description"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-end py-3 px-1" },
                [
                  _c(
                    "v-btn",
                    { attrs: { type: "submit", color: "#3F51B5", dark: "" } },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }