<template>
  <v-dialog :value="showFileDescriptionDialog" width="400px">
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div>Attach File</div>
        <v-spacer />
        <v-btn icon @click="$emit('file-description-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <form class="px-5" @submit.prevent="saveDescription">
        <validation-observer ref="form">
          <div
            v-for="f of fileDescriptions"
            :key="f.fileName"
            class="d-flex gap align-center"
          >
            <div style="width: 30%" class="truncate">
              {{ f.fileName }}
            </div>
            <validation-provider v-slot="{ errors, valid }" name="description">
              <v-text-field
                v-model="f.description"
                :error-messages="errors"
                :success="valid"
                label="Description"
                color="#3F51B5"
              >
              </v-text-field>
            </validation-provider>
          </div>
        </validation-observer>

        <div class="d-flex justify-end py-3 px-1">
          <v-btn type="submit" color="#3F51B5" dark>Save</v-btn>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";

export default {
  name: "FileDescriptionDialog",
  data() {
    return { mdiClose, fileDescriptions: [] };
  },
  props: {
    showFileDescriptionDialog: Boolean,
    uploadedFiles: Array,
  },
  beforeMount() {
    this.fileDescriptions = this.uploadedFiles.map((f) => {
      return {
        fileName: f.file.name,
        description: "",
      };
    });
  },
  methods: {
    async saveDescription() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      this.$emit("description-saved", this.fileDescriptions);
    },
  },
};
</script>
