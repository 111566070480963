var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "node-view-wrapper",
    {
      staticClass: "template-placeholder",
      attrs: { as: "span", "data-placeholder": _vm.node.attrs.placeholder }
    },
    [_c("span", [_vm._v(_vm._s(_vm.displayPlaceholder))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }