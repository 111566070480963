import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import TemplatePlaceholder from "@/components/shared/TemplatePlaceholder";
import { escape, unescape } from "lodash";

export default Node.create({
  name: "templatePlaceholder",
  group: "inline",
  inline: true,
  selectable: true,
  draggable: false,
  atom: true,
  parseHTML() {
    return [
      {
        tag: "span",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(HTMLAttributes),
      unescape(HTMLAttributes["data-placeholder"]),
    ];
  },
  addAttributes() {
    return {
      placeholder: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-placeholder"),
        renderHTML: (attributes) => ({
          "data-placeholder": escape(attributes.placeholder),
        }),
      },
    };
  },
  addCommands() {
    return {
      insertTemplatePlaceholder:
        (attributes) =>
        ({ chain }) => {
          return chain()
            .focus()
            .insertNode("templatePlaceholder", attributes)
            .insertText(" ")
            .run();
        },
    };
  },
  addNodeView() {
    return VueNodeViewRenderer(TemplatePlaceholder);
  },
});
