<template>
  <v-dialog :value="showConfirmFileDeleteDialog" width="500px">
    <v-card>
      <v-card-text class="py-4">
        <p class="py-2">Are you sure you want to delete this item?</p>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn color="#3F51B5" text @click="$emit('cancel')"> Cancel </v-btn>
        <v-btn color="#3F51B5" dark @click="$emit('delete')"> Delete </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmFileDeleteDialog",
  props: {
    showConfirmFileDeleteDialog: Boolean,
  },
};
</script>
