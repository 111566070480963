<template>
  <v-dialog
    :value="showUnsavedChangesWarningDialog"
    max-width="500px"
    persistent
  >
    <v-card>
      <v-card-text class="py-5">
        There are unsaved changes on the form. Are you sure you want close and
        lose your changes?
      </v-card-text>
      <v-card-actions class="d-flex justify-end gap flex-wrap">
        <v-btn text @click="$emit('return-to-form')" color="#3F51B5">
          Return to Form
        </v-btn>
        <v-btn text @click="$emit('close-and-lose-changes')" color="#3F51B5">
          Close and Lose Changes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UnsavedChangesDialog",
  props: {
    showUnsavedChangesWarningDialog: Boolean,
  },
};
</script>
